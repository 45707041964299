@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800);
@font-face {
  font-family: "Beyond";
  src: url(../font/beyond_the_mountains.otf) format("truetype"),
    url(../font/beyond_the_mountains.ttf) format("opentype");
}

:root {
  --primary-color: #fa9e1b;
  --white-color: #fff;
  --violet: #8d4fff;
  --yellow: #fa9e1b;
  --black: #2d2c2c;
  --btn-black: #31124b;
  --bg-header: rgba(54, 19, 84, 0.6);
  --bg-header-scroll: rgba(54, 19, 84, 0.85);
  --bg-btn: linear-gradient(to right, #fa9e1b, #8d4fff, #fa9e1b);
  --bg-section: linear-gradient(to right, #fa9e1b, #8d4fff);
  --offer-color: #eda84a;
  --border: linear-gradient(to right, #fa9e1b, #8d4fff);
}

* {
  margin: 0;
  padding: 0;
  text-decoration: none;
  box-sizing: border-box;
}

body {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 400;
  background: #fff;
  color: #a5a5a5;
  overflow-x: hidden;
}
/* Header */

.header {
  transition: all 0.9s ease-in-out;
}

.header .scroll {
  background: rgba(54, 19, 84, 0.85);
  height: 100px;
  position: fixed;
  z-index: 99;
  animation: toTop 1s ease forwards;
}

@keyframes toTop {
  from {
    height: 135px;
  }
  to {
    height: 100px;
  }
}

.header .scroll .header__nav__container {
  padding: 20px 0;
}

.header .scroll .header__nav__container--search input {
  transform: translateY(60%);
}

.header .row {
  margin: 0 15px;
}

.header__nav {
  position: absolute;
  z-index: 2;
  width: 100%;
  background: rgba(54, 19, 84, 0.6);
}

.hide {
  display: none;
}

.header__topbar {
  width: 100%;
  height: 36px;
  background: #350a4e;
  position: absolute;
  z-index: 3;
}

.header__topbar__container {
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 600;
  color: var(--white-color);
  line-height: 36px;
}

.header__topbar__container .row {
  justify-content: space-between;
  align-items: center;
}

.header__topbar__container--contacts {
  cursor: pointer;
  display: flex;
  align-items: center;
}
.header__topbar__container--contacts span:nth-child(1) {
  padding-right: 45px;
  padding-bottom: 5px;
}
.header__topbar__container--contacts .i {
  transition: all 0.3s ease;
  font-size: 18px;
  margin-top: 10px;
}

.header__topbar__container--contacts a {
  color: var(--white-color);
  padding: 0 10px;
}

.header__topbar__container--register {
  text-align: right;
}

.header__topbar__container--register li {
  list-style: none;
  display: inline-block;
}

.header__topbar__container--register a {
  color: var(--white-color);
}

.header__topbar__container--contacts .i:hover,
.header__topbar__container--register a:hover {
  color: var(--yellow);
}

.header__nav__container {
  text-transform: uppercase;
  padding: 40px 0;
  padding-top: 60px;
}

.header__nav__container .row {
  align-items: center;
  justify-content: space-between;
}

.header__nav__container a {
  color: var(--white-color);
  padding-bottom: 30px;
}

.header__nav__container--logo > img {
  width: 100%;
}

.header__nav__container--list {
  text-align: right;
  font-weight: 600;
}

.header__nav__container--list li {
  list-style: none;
  margin-right: 35px;
  display: inline-block;
  position: relative;
}

.header__nav__container--list a::after {
  display: block;
  position: absolute;
  left: -1px;
  bottom: -8px;
  width: calc(100% + 2px);
  height: 2px;
  background: linear-gradient(to right, #fa9e1b, #8d4fff);
  content: "";
  opacity: 0;
  transition: all 300ms ease;
}

.header__nav__container--list a:hover::after {
  opacity: 1;
}

.header__nav__container--list a.active::after {
  opacity: 1;
}

.header__nav__container--search {
  text-align: right;
  position: relative;
  color: var(--white-color);
}

.header__nav__container--search .active {
  opacity: 1;
}

.header__nav__container--search i {
  font-size: 18px;
  transform: rotate(90deg);
  transition: all 300ms ease;
  cursor: pointer;
}

.header__nav__container--search i:hover {
  color: var(--yellow);
}

.header__nav__container--search input {
  height: 40px;
  width: 95%;
  position: absolute;
  border: none;
  outline: none;
  left: 0;
  margin-top: 18px;
  opacity: 0;
  padding-left: 15px;
  border-radius: 5px;
  transition: all 0.5s ease;
  transform: translateY(50%);
  box-shadow: 0 8px 20px rgb(0 0 0 / 15%);
  border-radius: 7px;
}

.header__nav__container--search input.active {
  opacity: 1;
  transform: translateY(0);
}

.list-tablet-mobile {
  display: none;
}

.header__menu__overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 100;
  display: none;
}

@keyframes open {
  from {
    right: -320px;
  }
  to {
    right: 0;
  }
}

@keyframes close {
  from {
    width: 320px;
  }
  to {
    width: 0;
  }
}

.header__menu__body--content {
  padding: 30px 50px;
  display: flex;
  justify-content: space-between;
  position: fixed;
  right: -320px;
  top: 0;
  width: 320px;
  height: 100%;
  background: linear-gradient(-300deg, #fa9e1b, #8d4fff);
  z-index: 100;
  transition: all 0.7s ease-in-out;
}

.header__menu__body--content.open {
  right: 0;

}

.header__menu__body--content .i {
  text-align: right;
  color: var(--white-color);
  font-size: 25px;
  transition: all 0.3s ease-in;
  cursor: pointer;
}

.header__menu__body--content .i:hover {
  color: var(--yellow);
}

.header__menu__body--content ul {
  list-style: none;
}

.header__menu__body--content ul a:hover,
.header__menu__body--content ul a.active {
  color: var(--yellow);
}

.header__menu__body--content a {
  /* font-family: "Beyond"; */
  color: var(--white-color);
  font-size: 25px;
  padding: 25px 0;
  display: block;
}

.scroll-top {
  position: fixed;
  right: 15px;
  bottom: 50px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: var(--yellow);
  cursor: pointer;
  z-index: 99;
  display: none;
  transition: all 0.5s ease;
}

.scroll-top i {
  font-size: 22px;
  color: var(--white-color);
  margin-left: 50%;
  transform: translateX(-50%);
  margin-top: 25%;
  transition: all 0.3s ease;
}

.scroll-top i:hover {
  color: var(--black);
}
/* Footer */

.footer {
  width: 100%;
  padding-top: 100px;
  padding-bottom: 20px;
  background: #31124b;
}

.footer__container h2 {
  font-size: 18px;
  font-weight: 700;
  color: #fff;
  text-transform: uppercase;
  margin-bottom: 38px;
}

.footer__container .row {
  position: relative;
}

.footer__container .col {
  padding: 0 15px;
}

.container__about--logo {
  width: 100%;
}
.container__about--logo img {
  width: 100%;
  padding-bottom: 20px;
}
.footer__container__about a {
  font-size: 30px;
  color: #f4f4f8;
  font-weight: 800;
  text-transform: uppercase;
}

.container__about--text {
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  line-height: 2;

  margin-bottom: 15px;
}

.container__about--social ul {
  list-style: none;
}

.container__about--social ul li {
  display: inline-block;
  width: 31px;
  height: 31px;
  border: solid 1px #fa9e1b;
  border-radius: 50%;
  text-align: center;
  transition: all 200ms ease;
  margin-right: 9px;
}

.container__about--social li:hover {
  background: var(--yellow);
}

.container__about--social li:hover .social {
  color: var(--black);
}

.container__about--social a {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
}

.container__about--social .social {
  display: block;
  position: relative;
  color: #fff;
  top: 50%;
  left: 7px;
  transform: translateY(-50%);
  font-size: 15px;
  transition: all 200ms ease;
}

.container__blog__item--img {
  display: flex;
  margin-bottom: 20px;
}

.container__blog__item--img img {
  width: 60px;
  height: 60px;
}

.container__blog__item--text {
  margin-left: 20px;
}

.container__blog__item--text a {
  font-size: 14px;
  font-weight: 400;
  color: #fff;
}

.container__blog__item--text a:hover {
  color: var(--yellow);
}

.container__blog__item--text span {
  font-size: 12px;
  font-weight: 400;
  color: var(--yellow);
  margin-top: 7px;
  display: block;
}

.footer__container__tags ul li {
  list-style: none;
  height: 35px;
  display: inline-block;
  margin-right: 9px;
  margin-bottom: 9px;
  border: solid 1px #fa9e1b;
  transition: all 200ms ease;
}

.footer__container__tags ul li:hover {
  background-color: var(--yellow);
}

.footer__container__tags a {
  display: block;
  font-size: 12px;
  font-weight: 600;
  color: #fff;
  line-height: 33px;
  padding-left: 25px;
  padding-right: 25px;
}

.container__contact__info {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.container__contact__info span {
  color: #fff;
  line-height: 2.14;
  margin-top: -4px;
  cursor: pointer;
}

.container__contact__info span:hover {
  color: var(--yellow);
}

.container__contact__info--img {
  width: 30px;
  margin-right: 10px;
  max-width: 100%;
}

.container__contact__info--img img {
  display: block;
  width: 30px;
}

.copyright {
  text-align: center;
  display: block;
  margin-top: 35px;
  color: rgb(202, 202, 202);
  font-weight: 700;
}

.copyright a {
  color: var(--yellow);
}

.copyright a:hover {
  opacity: 0.9;
}
